import React from "react"
import { Typography } from "@material-ui/core"

const Terms = dialog => {
  return (
    <div style={{ maxWidth: "600px" }}>
      <Typography variant={dialog ? "h6" : "h5"} paragraph>
        Kidney24 Website - Legal Information
      </Typography>
      <Typography variant={dialog ? "body1" : "h6"} paragraph>
        Disclaimer
      </Typography>
      <Typography paragraph>
        Please read the following terms and conditions carefully. By viewing the
        pages in this site, you agree to these terms and conditions. If you do
        not agree, you should not view this site.
      </Typography>
      <Typography variant={dialog ? "body1" : "h6"} paragraph>
        Restrictions on Use of Materials on the Kidney24 Website
      </Typography>
      <Typography paragraph>
        The information, text, logos, images, pictures, video or audio
        (collectively, the Content) included in and constituting the Kidney24
        Website may not be used in any form in whole or in part without prior
        written permission of the Owners of this Website. Single copies of the
        Content of the Website may be printed for personal or educational
        purposes without permission, and must include the original copyright
        notice.
      </Typography>
      <Typography variant={dialog ? "body1" : "h6"} paragraph>
        Limitation of Liability
      </Typography>
      <Typography>
        All material on this site is prepared with care to ensure fair balance,
        objectivity, independence, and relevance. The editors of the material
        herein have consulted sources believed to be reliable in their efforts
        to provide information that is complete and in accord with the standards
        accepted at the time of posting. However, neither the Owner of Kidney24
        nor any other party involved in the preparation of this work, warrants
        that the information contained herein is in every respect accurate or
        complete, and they are not responsible for any errors or omissions or
        for the results obtained from the use of such. Readers are encouraged to
        confirm the information contained herein with other sources. Patients
        and consumers reading articles posted on this site should review the
        information carefully with their professional health care provider.
        <strong>
          The information is not intended to replace medical advice offered by
          physicians.
        </strong>{" "}
        The Owner of Kidney24 is not liable, under any circumstances, for any
        losses or damages from the use of the Content of this website. Use of
        this site is at your own risk. The Owner of Kidney24 make no
        representations or warranties with respect to any treatment, action, or
        application of medication or preparation by any person following the
        information offered or provided within or through this website or any
        links that may be contained herein. The Owner of Kidney24 and its
        partners will not be liable for any direct, indirect, consequential,
        special, exemplary, or other damages arising therefrom.
      </Typography>
    </div>
  )
}

export default Terms
