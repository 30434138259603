import React, { useState } from "react"
import { Typography, TextField, Button, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles";
import { graphql, useStaticQuery } from "gatsby"
import * as EmailValidator from 'email-validator';
import Alert from "@material-ui/lab/Alert";
import ReCAPTCHA from "react-google-recaptcha"

const useStyles = makeStyles(theme => ({
  input: {
    marginBottom: "15px"
  },
  contactInfo: {
    marginTop: "30px",
    maxWidth: "250px"
  },
  alert: {
    marginTop: "10px",
    marginBottom: "20px"
  },
  progress: {
    marginTop: "15px",
    marginRight: "5px"
  }
}))

const queryImage = graphql`
  query contactImg {
    file(relativePath: { eq: "contact.svg" }) {
      publicURL
    }
  }
`

const Contact = dialog => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [submittedData, setSubmittedData]=useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [submitStatus, setSubmitStatus] = useState("")

  const [isSending, setIsSending] = useState(false)

  const classes = useStyles()

  const { file: imageFile } = useStaticQuery(queryImage)

  const [token, setToken] = useState("")

  const onChange = (value) => {
    setToken(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault() 
    setIsSending(true)
    
    const data = JSON.stringify({ name, email, message, token })
    
    fetch('/api/sendMessage', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body: data
    })
    .then(res => res.json())
    .then(data => {
      if (data.status === "success") {
        setSubmittedData(data.msg)
        setSubmitStatus("success")
      } else {
        setSubmittedData(null)
        setSubmitStatus("failed")
      }
      setSubmitted(true)
      setIsSending(false)
    })
  }

  const getAlert = () => {
    if (submitStatus==="success") {
      return (
        <Alert className={classes.alert} variant="standard">Your message was sent successfully.</Alert>
      )
    } else {
      return (
      <Alert className={classes.alert} variant="standard" severity="warning">Message delivery failed.</Alert>
      )
    }
  }
  
  return (
    <div>
      <Typography variant={dialog ? "h6" : "h5"} paragraph>
        Kidney24 Website - Contact Us
      </Typography>

      {submitted? getAlert(): null}
      
      {(submitted & submitStatus==="success")? 
      <div>
        <p><strong>Name</strong>: {submittedData.name}</p>
        <p><strong>E-Mail</strong>: {submittedData.email}</p>
        <p><strong>Message</strong>: {submittedData.message}</p>
        <Button 
          variant="contained" 
          onClick={() => setSubmitted(false)}
          >Resend Message</Button>
      </div>
      :<form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          className={classes.input}
          size="small"
          required
          variant="outlined"
          label="Your name"
          value={name}
          onChange={event => {
            setName(event.target.value)
          }}
        />
        <TextField
          fullWidth
          className={classes.input}
          size="small"
          required
          variant="outlined"
          label="Your email address"
          value={email}
          onChange={event => {
            setEmail(event.target.value)
          }}
          error={email !== "" && !EmailValidator.validate(email)}
        />
        <TextField
          fullWidth
          className={classes.input}
          multiline
          size="small"
          rows={5}
          required
          variant="outlined"
          label="Your message"
          value={message}
          onChange={event => {
            setMessage(event.target.value)
          }}
        />

        <ReCAPTCHA
          className={classes.input}
          render="explicit"
          sitekey="6Lf3h-AZAAAAAD6apjZdaHtzsLKmdXGDv5CXD9ng"
          onChange={onChange}
          hl="en"
        />

        <Button 
          variant="contained" 
          color="primary" 
          type="submit"
          disabled={token === "" || name.trim() === "" || !EmailValidator.validate(email) || message.trim() === "" }
          >Submit</Button>
        {isSending? <div>
          <CircularProgress size={20} className={classes.progress}/> Sending
        </div>: null}

      </form>}

      <div>
        <img
            src={imageFile.publicURL}
            className={classes.contactInfo}
        />
      </div>
  </div>
  )
}

export default Contact
