import React from "react"
import { Typography } from "@material-ui/core"

const Privacy = dialog => {
  return (
    <div style={{ maxWidth: "600px" }}>
      <Typography variant={dialog ? "h6" : "h5"} paragraph>
        Kidney24 Website - Data Privacy
      </Typography>

      <Typography variant={dialog ? "body1" : "h6"} paragraph>
        Data Collection and Usage
      </Typography>
      <Typography paragraph>
        Currently we don't collect any personal data unless you voluntarily send
        a message via our contact form. In particular, we don't use browser
        cookies to track visitor behavior. Information sent to us by the contact
        form is kept private and is not used for other purposes than our
        communication. No data is given to third parties.
      </Typography>
      <Typography variant={dialog ? "body1" : "h6"} paragraph>
        Changes to our Privacy Policy
      </Typography>
      <Typography paragraph>
        We keep our privacy policy under regular review and place any updates on
        this web page.
      </Typography>
    </div>
  )
}

export default Privacy
