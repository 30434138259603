import React, { useContext } from "react"
import { useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/styles"
import { ScreenContext } from "../context/screenContext"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { Container, Tabs, Tab, Box } from "@material-ui/core"
import { AppContext } from "../context/appContext"
import Terms from "../components/about/terms"
import Privacy from "../components/about/privacy"
import Contact from "../components/about/contact";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "1800px",
    margin: "15px auto 40px auto",
    display: "flex",
  },
  containerMobile: {
    width: "100%",
    marginTop: "10px",
    paddingBottom: "100px",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabsMobile: {},
}))

const queryImages = graphql`
  query aboutImages {
    allFile(filter: { relativePath: { in: ["kidney_landing.jpg"] } }) {
      nodes {
        relativePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      <Box p={4}>{children}</Box>
    </div>
  )
}

const AboutPage = () => {
  const screen = useContext(ScreenContext)
  const { aboutTabIndex, setAboutTabIndex } = useContext(AppContext)

  const classes = useStyles()

  const {
    allFile: { nodes: images },
  } = useStaticQuery(queryImages)

  const getImage = relPath => {
    return images.filter(img => img.relativePath === relPath)[0].childImageSharp
      .fluid
  }

  const handleChange = (event, newValue) => {
    setAboutTabIndex(newValue)
  }

  return (
    <Layout activePage="home">
      <SEO title="Home" />
      <Container
        className={screen.tablet ? classes.containerMobile : classes.container}
      >
        <Tabs
          orientation={screen.tablet ? "horizontal" : "vertical"}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          value={aboutTabIndex}
          onChange={handleChange}
          className={screen.tablet ? classes.tabsMobile : classes.tabs}
        >
          <Tab label="Legal Information" />
          <Tab label="Data Privacy" />
          <Tab label="Contact" />
        </Tabs>

        <TabPanel value={aboutTabIndex} index={0}>
          <Terms />
        </TabPanel>
        <TabPanel value={aboutTabIndex} index={1}>
          <Privacy />
        </TabPanel>
        <TabPanel value={aboutTabIndex} index={2}>
          <Contact/>
        </TabPanel>
      </Container>
    </Layout>
  )
}

export default AboutPage
